// assets
import { DashboardOutlined, HomeOutlined } from '@ant-design/icons';


// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Đơn hàng',
      type: 'item',
      url: '/home',
      icon: HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'product',
      title: 'Sản phẩm',
      type: 'item',
      url: '/product',
      icon: DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'khungsatinox',
      title: 'Khung sắt, inox',
      type: 'item',
      url: '/khungsatinox',
      icon: DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
