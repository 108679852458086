import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearUserData, getAccessToken, getRefreshToken, setUserData, updateAccessToken } from 'utils/auth';
import axiosInstance from 'axiosConfig';
import { apiUrls } from 'utils/api';
const ACCESS_TOKEN_EXPIRES_TIME = 1000 * 60 * 9;

const AuthenticationMiddleware = ({ children }) => {
  const navigate = useNavigate();
  const refreshToken = getRefreshToken() || null;
  const isAuthenticated = !!localStorage.getItem('user');
  const [isFirstMounted, setIsFirstMounted] = useState(true);

  const handleLogin = (userData) => {
    setUserData(userData);
    //const origin = (location.state as LocationState)?.from?.pathname || '/home';
    navigate('/');
  };

  const handleLogout = async () => {
    clearUserData();
    // Also remove user's refresh token from server
    const response = await axiosInstance.post(apiUrls.logout, {
      refreshToken: refreshToken
    });

    navigate('/login');
  };

  async function updateRefreshToken() {
    try {
      const response = await axiosInstance.post(apiUrls.token, {
        refreshToken: refreshToken
      });

      updateAccessToken(response.data.accessToken);
    } catch (error) {
      console.log('updateRefreshToken Failed!:', error);
      handleLogout();
    }

    if (isFirstMounted) {
      setIsFirstMounted(false);
    }
  }

  useEffect(() => {
    if(!isAuthenticated){
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <>{children}</> : null;
};

export default AuthenticationMiddleware;
