export const apiUrls = {
  login: '/auth/login',
  logout: '/auth/logout',
  token: '/auth/token',
  product_update: '/products/update',
  product_list: '/products/list',
  product_store: '/products/store',
  product_delete: '/products/delete/:id',
  order_update: '/order/update',
  order_list: '/order/list',
  order_store: '/order/store',
  order_delete: '/order/delete/:id',
};
