import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthenticationMiddleware from '../middleware/authMiddleware';

const HomeDetailPage = Loadable(lazy(() => import('pages/home/detail')));
const KhungSatInoxPage = Loadable(lazy(() => import('pages/khungsatinox')));

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const HomePage = Loadable(lazy(() => import('pages/home')));
const ProductPage = Loadable(lazy(() => import('pages/product')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthenticationMiddleware>
      <MainLayout />
    </AuthenticationMiddleware>
  ),
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'home',
      children: [
        {
          path: '',
          element: <HomePage />
        },
        {
          path: ':id',
          element: <HomeDetailPage />
        }
      ]
    },
    {
      path: 'product',
      element: <ProductPage />
    },
    {
      path: 'khungsatinox',
      element: <KhungSatInoxPage />
    },
    
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
