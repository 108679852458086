//import { useContext } from 'react';
//import { AuthContext } from '../contexts/AuthContext';

// export TUser = {
//     accessToken: string;
//     refreshToken: string;
// };

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};

export const setUserData = (user) => {
  if (Object.keys(user).length === 0) {
    throw new Error('No data found');
  }

  localStorage.setItem('user', JSON.stringify(user));
};

export function clearUserData() {
  localStorage.removeItem('user');
}

export const getRefreshToken = () => {
  return JSON.parse(localStorage.getItem('user') || '{}')?.refreshToken;
};

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem('user') || '{}')?.accessToken;
};

export const updateAccessToken = (token) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  user.accessToken = token;
  localStorage.setItem('user', JSON.stringify(user));
};

export const isAuthenticated = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return false;
  return true;
};

export function getPayloadFromToken(token) {
  if (!token) {
    return {};
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
