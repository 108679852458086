import axios from 'axios';
import environment from 'environment';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: environment.apiUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  proxy: {
    protocol: 'http',
    host: 'localhost',
    port: 3005
  }
});
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const userLogin = localStorage.getItem('user');
    if (userLogin) {
      const userLoginJson = JSON.parse(userLogin);
      config.headers = {
        Authorization: `Bearer ${userLoginJson.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    toast.error(error.message);
    return [];
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
    toast.error(error.message);
    return [];
  }
);

export default axiosInstance;
