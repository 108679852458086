// ==============================|| OVERRIDES - CHIP ||============================== //

export default function MuiDataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeaderTitle':{
            fontSize: '0.875rem',
            fontWeight: 800,
            color: '#1677ff'
          },
          '& .MuiDataGrid-cell': {
            fontSize: '0.8rem'
          }
        },

      }
    },
  };
}
